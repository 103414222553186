import {
    BankDepositDTO,
    CashReportDataDTO,
    CashReportDataType,
    CashReportDTO,
    PaymentsDTO,
    SalariesDTO
} from "./CashReportPage.type";
import {CommandResult} from "../../common/service/result/Result.type";
import {DictionaryDTO} from "../../common/service/util/dictionary/Dictionary.type";
import {_delete, get, handleZipDownload, post, put} from "../../common/service/http/Http.service";
import {UriBuilder} from "../../common/service/util/UriBuilder.class";
import {isDefined} from "../../common/service/util/ObjectUtils";
import {FileRequestBuilder} from '../../common/service/util/FileRequestBuilder.class';

export const getCashReportRest = (month: number, year: number): Promise<CashReportDTO> => {
    return get(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .build())
}

export const getEmployeesRest = (month: number, year: number): Promise<DictionaryDTO[]> => {
    return get(new UriBuilder()
        .all("user")
        .all("active")
        .params({month: month, year: year})
        .build());
}

export const getCurrentOfferRest = (month: number, year: number): Promise<DictionaryDTO[]> => {
    return get(new UriBuilder()
        .all("offer")
        .all("active")
        .params({month: month, year: year})
        .build());
}

export const deleteCashReportData = (month: number, year: number, data: CashReportDataDTO): Promise<CommandResult> => {
    switch (data.type) {
        case CashReportDataType.SALARIES:
            return deleteSalariesRest(month, year);
        case CashReportDataType.BANK_DEPOSIT:
            return deleteBankDepositRest(month, year, data.id);
        case CashReportDataType.PAYMENTS:
            return deletePaymentsRest(month, year, data.id);
    }
}

const deleteSalariesRest = (month: number, year: number) => {
    return _delete<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("salaries")
        .all("delete")
        .build());
}

const deleteBankDepositRest = (month: number, year: number, id: string) => {
    return _delete<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .one("bankDeposit", id)
        .all("delete")
        .build());
}

const deletePaymentsRest = (month: number, year: number, id: string) => {
    return _delete<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .one("payment", id)
        .all("delete")
        .build());
}

export const savePaymentsRest = (month: number, year: number, id: string, data: PaymentsDTO, files: File[]): Promise<CommandResult> => {
    const formData: FormData = new FileRequestBuilder()
        .withFiles(files, 'files')
        .withObject({...data, date: data.date.dateToUrlString()}, 'paymentsDTO')
        .build()

    return isDefined(id)
        ? updatePaymentsRest(id, month, year, formData)
        : addPaymentsRest(month, year, formData);
}

const updatePaymentsRest = (id: string, month: number, year: number, formData: FormData): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .one("payment", id)
        .all("update")
        .build(), formData);
}

const addPaymentsRest = (month: number, year: number, formData: FormData): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("payment")
        .build(), formData);
}

export const saveSalariesRest = (month: number, year: number, data: SalariesDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("salaries")
        .build(), data);
}

export const updateSalariesRest = (month: number, year: number, data: SalariesDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("salaries")
        .all("update")
        .build(), data);
}

export const saveBankDepositRest = (month: number, year: number, data: BankDepositDTO): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("bankDeposit")
        .build(), data);
}

export const updateBankDepositRest = (month: number, year: number, id: string, data: BankDepositDTO): Promise<CommandResult> => {
    return put<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .one("bankDeposit", id)
        .all("update")
        .build(), data);
}

export const acceptCashReportRest = (month: number, year: number): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("accept")
        .build());
}

export const recalculateReportRest = (month: number, year: number): Promise<CommandResult> => {
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("recalculate")
        .build());
}

export const importPaymentsRest = (month: number, year: number, file: File): Promise<CommandResult> => {
    const formData = new FileRequestBuilder().withFile(file, "file").build();
    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("importPayments")
        .build(), formData);
}

export const importProofsRest = (month: number, year: number, file: File): Promise<CommandResult> => {
    const formData = new FileRequestBuilder().withFile(file, "file").build();

    return post<CommandResult>(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("importProofs")
        .build(), formData);
}

export const downloadCashReportRest = (month: number, year: number): void => {
    handleZipDownload(new UriBuilder()
        .all("cashReport")
        .one("month", month)
        .one("year", year)
        .all("generate")
        .build());
}

export const getCashReportFilesUrl = (id: string): string => {
    return new UriBuilder()
        .all('cashReport')
        .one('month', 2)
        .one('year', 2023)
        .all('files')
        .one('parent', id)
        .build();
}
