import React, {FC, useEffect, useState} from "react";
import {DayRange} from "../../common/service/util/DayRange";
import {translate} from "../../common/lang/service/Translate.service";
import {Day} from "../../common/service/util/Day";
import {PeriodChanger} from "../../common/components/periodChanger/PeriodChanger.component";
import {getChangePeriodByMonth} from "../../common/components/periodChanger/service/PeriodChangeStrategy";
import {getMonthYearChangerDisplay} from "../../common/components/periodChanger/service/PeriodChangerDisplay";
import {Card} from "../../common/components/card/Card.component";
import {Toolbar} from "primereact/toolbar";
import {SplitButton} from "primereact/splitbutton";
import {Button} from "primereact/button";
import {getEmployeesRest} from "../../cashReportPage/service/CashReportPage.service";
import {DictionaryDTO} from "../../common/service/util/dictionary/Dictionary.type";
import {getDictionarySelectOption} from "../../common/components/input/multiselectChips/service/SelectOption.type";
import {LabelType} from "../../common/components/input/inputLabel/InputLabel.component";
import {SingleSelectInput} from "../../common/components/input/singleSelectInput/SingleSelectInput.component";
import {useNavigate, useParams} from "react-router-dom";
import {isDefined} from "../../common/service/util/ObjectUtils";
import {usePermissions} from "../../common/security/globalPermissions/service/Permissions.hook";

interface AppointmentsToolbarProps {
    isTattooer: boolean;
    isToothGemmer: boolean;
    isPiercer: boolean;
    isDermaler: boolean;

    onPeriodChange: (period: DayRange) => void;
    monthConfirmed: boolean;
    confirmMonth: () => void;

    addPiercingAppointment: () => void;
    addToothGemAppointment: () => void;
    addTattooAppointment: () => void;
    addDermalAppointment: () => void;
    isForOtherUsers: boolean;
    month: number;
    year: number;
}

export const AppointmentsToolbar: FC<AppointmentsToolbarProps> = (props: AppointmentsToolbarProps) => {
    const [employeesDictionary, setEmployeesDictionary] = useState<DictionaryDTO[]>([])

    useEffect(() => {
        if (props.isForOtherUsers) {
            retrieveEmployees();
        }
    }, [props.month, props.year]);

    async function retrieveEmployees() {
        const employeeDtos = await getEmployeesRest(props.month, props.year);
        setEmployeesDictionary(employeeDtos);
    }

    const navigate = useNavigate();
    const {userId} = useParams();
    const canAddAnyUserAppointments = usePermissions().canAddAnyUserAppointments && props.isForOtherUsers && isDefined(userId);
    const getItems = () => [
        {
            label: translate("ADD_TATTOO"),
            command: () => {
                props.addTattooAppointment();
            },
            visible: props.isTattooer || canAddAnyUserAppointments
        },
        {
            label: translate("ADD_PIERCING"),
            command: () => {
                props.addPiercingAppointment();
            },
            visible: props.isPiercer || canAddAnyUserAppointments
        },
        {
            label: translate("ADD_TOOTH_GEMS"),
            command: () => {
                props.addToothGemAppointment();
            },
            visible: props.isToothGemmer || canAddAnyUserAppointments
        },
        {
            label: translate("ADD_DERMALS"),
            command: () => {
                props.addToothGemAppointment();
            },
            visible: props.isDermaler || canAddAnyUserAppointments
        },
    ];

    const changeSelectedUser = (userId: string) => {
        navigate(`/others-appointments/${userId}`)
    }

    const rightToolbarTemplate = () => {
        const items = getItems().filter(value => value.visible);
        const firstItem = items.getFirst();

        const showButtons = () => {
            return (!(props.monthConfirmed || items.isEmpty()) && !props.isForOtherUsers) || (props.isForOtherUsers && isDefined(userId));
        }

        return (
            <>
                {props.isForOtherUsers && <SingleSelectInput
                    options={getDictionarySelectOption(employeesDictionary)}
                    labelType={LabelType.INLINE}
                    label={"User"}
                    selectedValue={userId}
                    setSelectedValue={selectedValue => changeSelectedUser(selectedValue)}
                    className={"mr-2"}
                />}
                {showButtons() && <>
                    <Button
                        label={translate("ACCEPT")}
                        onClick={props.confirmMonth}
                        icon={"pi pi-check"}
                        className={"p-button-success mr-2"}
                    />
                    {items.length !== 1
                        ? <SplitButton
                            label={firstItem.label}
                            onClick={firstItem.command}
                            icon="pi pi-plus"
                            model={items.slice(1, items.length)}
                        />
                        : <Button
                            label={firstItem.label}
                            onClick={firstItem.command}
                            icon={"pi pi-plus"}
                            className={"mr-2"}
                        />}
                </>}
            </>
        );
    };

    const leftToolbarPanel = () => {
        const startPeriod = new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay());
        return (
            <PeriodChanger
                startPeriod={startPeriod}
                onNextPeriod={props.onPeriodChange}
                onPreviousPeriod={props.onPeriodChange}
                dayInFirstPeriod={Day.getCompanyStartDate()}
                changePeriod={getChangePeriodByMonth()}
                display={getMonthYearChangerDisplay()}
            />
        );
    }

    return <div className={"col-12"}>
        <Card>
            <Toolbar
                start={leftToolbarPanel}
                end={rightToolbarTemplate}
            />
        </Card>
    </div>;
};
