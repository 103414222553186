import React, {FC, useEffect, useState} from "react";
import {AppointmentsToolbar} from "./appointmentsToolbar/AppointmentsToolbar";
import {DayRange} from "../common/service/util/DayRange";
import {Day} from "../common/service/util/Day";
import {AppointmentCard} from "./appointmentCard/AppointmentCard.component";
import {
    AppointmentDTO,
    AppointmentsDTO,
    AppointmentsSummaryDTO,
    AppointmentType,
    UserMonthlyAppointmentsStatus
} from "./service/Appointments.type";
import {acceptMonthRest, getAppointmentsRest} from "./service/Appointments.service";
import {AppointmentsSummary} from "./appointmentsSummary/AppointmentsSummary.component";
import {StyledAppointmentsPage} from "./AppointmentsPage.style";
import {usePermissions} from "../common/security/globalPermissions/service/Permissions.hook";
import {ConfirmationModal} from "../common/modal/confirmationModal/ConfirmationModal.component";
import {AddNewAppointmentModal} from "./modals/addNewAppointmentModal/AddNewAppointmentModal.component";
import {ConfirmAppointmentModal} from "./modals/confirmAppointmentModal/ConfirmAppointmentModal.component";
import {EditAppointmentModal} from "./modals/editAppointmentModal/EditAppointmentModal.component";
import {FinishAppointmentModal} from "./modals/finishAppointmentModal/FinishAppointmentModal.component";
import {CancelAppointmentModal} from "./modals/cancelAppointmentModal/CancelAppointmentModal.component";
import {useParams} from "react-router-dom";
import {isDefined} from "../common/service/util/ObjectUtils";

interface AppointmentsPageProps {
    isForOtherUsers?: boolean;
}

export const AppointmentsPage: FC<AppointmentsPageProps> = (props: AppointmentsPageProps) => {
    const [currentPeriod, setCurrentPeriod] = useState<DayRange>(new DayRange(Day.today().getMonthFirstDay(), Day.today().getMonthLastDay()));
    const [appointments, setAppointments] = useState<AppointmentDTO[]>([]);
    const [summary, setSummary] = useState<AppointmentsSummaryDTO>(undefined);
    const [status, setStatus] = useState<UserMonthlyAppointmentsStatus>(UserMonthlyAppointmentsStatus.CONFIRMED);
    const globalPermissions = usePermissions();
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

    const [addNewAppointmentVisible, setAddNewAppointmentVisible] = useState(false);
    const [finishAppointmentVisible, setFinishNewAppointmentVisible] = useState(false);
    const [cancelAppointmentVisible, setCancelAppointmentVisible] = useState(false);
    const [confirmAppointmentVisible, setConfirmAppointmentVisible] = useState(false);
    const [editAppointmentVisible, setEditAppointmentVisible] = useState(false);

    const [type, setType] = useState<AppointmentType>(undefined);
    const [appointment, setAppointment] = useState<AppointmentDTO>(undefined);

    const userId: string = useParams().userId;

    useEffect(() => {
        if (userId != null || !props.isForOtherUsers) {
            retrieveAppointments(currentPeriod);
        }
    }, [props.isForOtherUsers, userId]);

    async function retrieveAppointments(period: DayRange) {
        const middleDay = period.getMiddleDay();
        const appointmentsDTO: AppointmentsDTO = await getAppointmentsRest(middleDay.getMonthNumber(), middleDay.getFullYear(), userId);
        setAppointments(appointmentsDTO.appointments);
        setSummary(appointmentsDTO.summary);
        setStatus(appointmentsDTO.status)
    }

    const onPeriodChange = (period: DayRange): void => {
        retrieveAppointments(period);
        setCurrentPeriod(period);
    }

    const openNewAppointmentModal = (type: AppointmentType) => {
        setType(type);
        setAddNewAppointmentVisible(true);
    }

    const openConfirmAppointmentModal = (appointment: AppointmentDTO) => {
        setAppointment(appointment);
        setConfirmAppointmentVisible(true);
    }

    const openAcceptAppointmentModal = (appointment: AppointmentDTO) => {
        setAppointment(appointment);
        setFinishNewAppointmentVisible(true);
    }

    const openEditAppointmentModal = (appointment: AppointmentDTO) => {
        setAppointment(appointment);
        setEditAppointmentVisible(true);
    }

    const openCancelAppointmentModal = (appointment: AppointmentDTO) => {
        setAppointment(appointment);
        setCancelAppointmentVisible(true);
    }

    const openConfirmationModal = () => {
        setConfirmationModalVisible(true);
    }

    const confirmMonth = async () => {
        const middleDay = currentPeriod.getMiddleDay();
        await acceptMonthRest(middleDay.getMonthNumber(), middleDay.getFullYear(), userId);
        setConfirmationModalVisible(false);
        retrieveAppointments(currentPeriod);
    }

    function getMonth() {
        const middleDay = currentPeriod.getMiddleDay();
        return middleDay.getMonthNumber();
    }

    function getYear() {
        const middleDay = currentPeriod.getMiddleDay();
        return middleDay.getFullYear();
    }

    return <StyledAppointmentsPage className={"grid"}>
        <AppointmentsToolbar
            addPiercingAppointment={() => openNewAppointmentModal(AppointmentType.PIERCING)}
            addTattooAppointment={() => openNewAppointmentModal(AppointmentType.TATTOO)}
            addToothGemAppointment={() => openNewAppointmentModal(AppointmentType.TOOTH_GEMS)}
            addDermalAppointment={() => openNewAppointmentModal(AppointmentType.DERMALS)}
            confirmMonth={openConfirmationModal}
            monthConfirmed={status === UserMonthlyAppointmentsStatus.CONFIRMED}
            onPeriodChange={onPeriodChange}
            isToothGemmer={globalPermissions.canAddToothGems}
            isTattooer={globalPermissions.canAddTattoos}
            isDermaler={globalPermissions.canAddDermals}
            isPiercer={globalPermissions.canAddPiercing}
            isForOtherUsers={props.isForOtherUsers}
            month={getMonth()}
            year={getYear()}
        />

        {(!props.isForOtherUsers || isDefined(userId)) && <>
            <div className={"col-12 md:col-8"}>
                {appointments.map(value =>
                    <AppointmentCard
                        key={value.id}
                        appointment={value}
                        openConfirmAppointmentModal={openConfirmAppointmentModal}
                        openAcceptAppointmentModal={openAcceptAppointmentModal}
                        openEditAppointmentModal={openEditAppointmentModal}
                        openCancelAppointmentModal={openCancelAppointmentModal}
                    />
                )}
            </div>

            <div className={"col-12 md:col-4"}>
                <AppointmentsSummary
                    summary={summary}
                />
            </div>
            {addNewAppointmentVisible &&
                <AddNewAppointmentModal
                    type={type}
                    refreshAppointments={() => retrieveAppointments(currentPeriod)}
                    hideModal={() => setAddNewAppointmentVisible(false)}
                />}
            {confirmAppointmentVisible &&
                <ConfirmAppointmentModal
                    appointment={appointment}
                    refreshAppointments={() => retrieveAppointments(currentPeriod)}
                    hideModal={() => setConfirmAppointmentVisible(false)}
                />}
            {editAppointmentVisible &&
                <EditAppointmentModal
                    appointment={appointment}
                    refreshAppointments={() => retrieveAppointments(currentPeriod)}
                    hideModal={() => setEditAppointmentVisible(false)}
                />}
            {finishAppointmentVisible &&
                <FinishAppointmentModal
                    appointment={appointment}
                    refreshAppointments={() => retrieveAppointments(currentPeriod)}
                    hideModal={() => setFinishNewAppointmentVisible(false)}
                />}
            {cancelAppointmentVisible &&
                <CancelAppointmentModal
                    appointment={appointment}
                    refreshAppointments={() => retrieveAppointments(currentPeriod)}
                    hideModal={() => setCancelAppointmentVisible(false)}
                />}
            <ConfirmationModal onConfirm={confirmMonth} onReject={() => setConfirmationModalVisible(false)}
                               isVisible={confirmationModalVisible}/>
        </>}
    </StyledAppointmentsPage>;
};
